import axios from '../axios'

const apiKey = process.env.REACT_APP_TMDB_API_KEY

// Get the daily trending items.
export const fetchDailyTrending = async () => {
  const { data } = await axios.get(
    `/trending/all/day?api_key=${apiKey}&language=en-US`
  )
  return data.results
}

// Get the weekly trending items.
export const fetchWeeklyTrending = async () => {
  const { data } = await axios.get(
    `/trending/all/week?api_key=${apiKey}&language=en-US`
  )
  return data.results
}

// Get a list of Disney Plus Originals.
export const fetchDisneyOriginals = async () => {
  const { data } = await axios.get(
    `https://api.themoviedb.org/3/discover/tv?api_key=${apiKey}&with_networks=2739`
  )
  return data.results
}

// Get a list of Netflix Originals.
export const fetchNetflixOriginals = async () => {
  const { data } = await axios.get(
    `https://api.themoviedb.org/3/discover/tv?api_key=${apiKey}&with_networks=213`
  )
  return data.results
}

// Get a list of Prime Originals
export const fetchPrimeOriginals = async () => {
  const { data } = await axios.get(
    `https://api.themoviedb.org/3/discover/tv?api_key=${apiKey}&with_networks=1024`
  )
  return data.results
}

// Get a list of Hulu Originals.
export const fetchHuluOriginals = async () => {
  const { data } = await axios.get(
    `https://api.themoviedb.org/3/discover/tv?api_key=${apiKey}&with_networks=453`
  )
  return data.results
}

// Get a list of Apple TV Plus Originals
export const fetchAppleOriginals = async () => {
  const { data } = await axios.get(
    `https://api.themoviedb.org/3/discover/tv?api_key=${apiKey}&with_networks=2552`
  )
  return data.results
}

// Get a list of HBO Max Originals
export const fetchHboMaxOriginals = async () => {
  const { data } = await axios.get(
    `https://api.themoviedb.org/3/discover/tv?api_key=${apiKey}&with_networks=3186`
  )
  return data.results
}

// Get a list of Paramount Plus Originals
export const fetchParamountOriginals = async () => {
  const { data } = await axios.get(
    `https://api.themoviedb.org/3/discover/tv?api_key=${apiKey}&with_networks=4330`
  )
  return data.results
}

// Get a list of shows that has an episode with an air date in the next 7 days.
export const fetchTvOnTheAir = async () => {
  const { data } = await axios.get(
    `/tv/on_the_air?api_key=${apiKey}&language=en-US`
  )
  return data.results
}

// Get a list of movies in theatres.
export const fetchMoviesNowPlaying = async () => {
  const { data } = await axios.get(
    `/movie/now_playing?api_key=${apiKey}&language=en-US`
  )
  return data.results
}

// Get a list of the current popular movies on TMDB. This list updates daily.
export const fetchPopularMovies = async () => {
  const { data } = await axios.get(
    `/movie/popular?api_key=${apiKey}&language=en-US`
  )
  return data.results
}

// Get the top rated movies on TMDB.
export const fetchTopRatedMovies = async () => {
  const { data } = await axios.get(
    `/movie/top_rated?api_key=${apiKey}&language=en-US`
  )
  return data.results
}

// Get a list of TV shows that are airing today.
export const fetchTvAiringToday = async () => {
  const { data } = await axios.get(
    `/tv/airing_today?api_key=${apiKey}&language=en-US`
  )
  return data.results
}

// Get a list of the top rated TV shows on TMDB.
export const fetchTopRatedTv = async () => {
  const { data } = await axios.get(
    `/tv/top_rated?api_key=${apiKey}&language=en-US`
  )
  return data.results
}
