import React, { Component } from 'react'
import Slider from 'react-slick'
import Poster from './Poster'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './ShowSlider.css'

class ShowSlider extends Component {
  render() {
    const settings = {
      lazyLoad: true,
      infinite: false,
      speed: 500,
      slidesToShow: 8,
      slidesToScroll: 8
    }

    const { shows } = this.props

    return (
      <Slider {...settings}>
        {shows &&
          shows.map(show => (
            <Poster key={show.id} show={show} showRating={false} />
          ))}
      </Slider>
    )
  }
}

export default ShowSlider
