import React from 'react'
import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import '../components/Carousel.css'

const Carousel = ({ shows }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    // centerMode: true,
    arrows: false,
    lazyLoad: true
  }

  return (
    <div class="carousel__container">
      <div class="carousel">
        <div class="slider">
          {
            <Slider {...settings}>
              {shows &&
                shows.map(show => (
                  <div key={show.id}>
                    <div class="slide__content">
                      <h1 class="show__title">{show.name || show.title}</h1>
                      <p class="show__overview">{show.overview}</p>
                    </div>
                    <img
                      key={show.id}
                      src={`https://image.tmdb.org/t/p/original${show.backdrop_path}`}
                      alt={show.name || show.title}
                    />
                  </div>
                ))}
            </Slider>
          }
        </div>
      </div>
    </div>
  )
}

export default Carousel
