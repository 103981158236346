import React from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery, useImage } from '../hooks/index';
import Rating from './Rating';
import SkeletonCard from './SkeletonCard';
import formatDate from '../helpers/formatDate';

import '../components/Poster.css';

const Poster = ({ show, showRating }) => {
  let isPageWide = useMediaQuery('(min-width: 2000px');

  const imageUrl =
    show &&
    `https://image.tmdb.org/t/p/${isPageWide ? 'original' : 'w300'}${
      show.poster_path
    }`;

  const { hasLoaded, hasError } = useImage(imageUrl);

  if (hasError) {
    return null;
  }

  if (!hasLoaded) {
    return <SkeletonCard />;
  }

  return (
    <div className="test">
      <Link to={show.name ? `/tv/${show.id}` : `/movie/${show.id}`}>
        <p className="mb-2">
          <img
            className="poster__image"
            src={imageUrl}
            alt={show.name || show.title}
          />
        </p>
      </Link>

      <div className="poster__body">
        <Link
          to={show.name ? `/tv/${show.id}` : `/movie/${show.id}`}
          style={{ textDecoration: 'none' }}
        >
          <p className="text-white mb-0 text-small">
            {show.name || show.title}
          </p>
        </Link>

        <small className="text-white text-muted">
          {show.release_date || show.first_air_date
            ? formatDate(show.release_date || show.first_air_date)
            : null}
        </small>
      </div>

      {showRating ? (
        <div>
          <Rating value={show.vote_average} text={`${show.vote_count} votes`} />
        </div>
      ) : null}
    </div>
  );
};

export default Poster;
