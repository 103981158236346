import React from 'react';
import { Link } from 'react-router-dom';
import '../components/Scroller.css';

const base_url = 'https://image.tmdb.org/t/p/w300/';

const Scroller = ({ shows }) => {
  return (
    <div className="row__posters">
      {shows &&
        shows.map(show => (
          <Link
            to={show.name ? `/tv/${show.id}` : `/movie/${show.id}`}
            key={show.id}
          >
            <img
              key={show.id}
              className="row__poster"
              src={`${base_url}${show.poster_path}`}
              alt={show.original_title || show.original_name}
            />
          </Link>
        ))}
    </div>
  );
};

export default Scroller;
