import React from 'react';

const Rating = ({ value, text, color }) => {
  return (
    <div>
      <div className="rating">
        <span>
          <i
            style={{ color }}
            className={
              value >= 2
                ? 'fas fa-star'
                : value >= 1
                ? 'fas fa-star-half-alt'
                : 'far fa-star'
            }
          ></i>
        </span>
        <span>
          <i
            style={{ color }}
            className={
              value >= 4
                ? 'fas fa-star'
                : value >= 2
                ? 'fas fa-star-half-alt'
                : 'far fa-star'
            }
          ></i>
        </span>

        <span>
          <i
            style={{ color }}
            className={
              value >= 6
                ? 'fas fa-star'
                : value >= 5
                ? 'fas fa-star-half-alt'
                : 'far fa-star'
            }
          ></i>
        </span>

        <span>
          <i
            style={{ color }}
            className={
              value >= 8
                ? 'fas fa-star'
                : value >= 7
                ? 'fas fa-star-half-alt'
                : 'far fa-star'
            }
          ></i>
        </span>

        <span>
          <i
            style={{ color }}
            className={
              value >= 10
                ? 'fas fa-star'
                : value >= 9
                ? 'fas fa-star-half-alt'
                : 'far fa-star'
            }
          ></i>
        </span>
        <span>
          <small className="ml-1 text-muted">{text && text}</small>
        </span>
      </div>
    </div>
  );
};

Rating.defaultProps = {
  color: '#f8e825'
};

export default Rating;
