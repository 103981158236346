import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import './SkeletonCard.css';

const SkeletonCard = () => {
  return (
    <SkeletonTheme color="#202020" highlightColor="#444">
      <p className="mb-2">
        <Skeleton className="skeleton__image" />
      </p>

      <div className="skeleton__body">
        <p>
          <Skeleton count={1} />
          <Skeleton count={1} width={100} />
        </p>
      </div>
    </SkeletonTheme>
  );
};

export default SkeletonCard;
