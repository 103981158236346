import React from 'react'
import { Nav, Navbar, Container, NavDropdown } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

import './Navigation.css'

const Navigation = () => {
  return (
    <Navbar variant="dark" expand="lg" sticky="top" className="color-nav">
      <Container className="nav-container">
        <LinkContainer to="/">
          <Navbar.Brand className="pb-2">Showify</Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <NavDropdown
              title="Movies"
              className="mr-3"
              id="basic-nav-dropdown"
            >
              <LinkContainer to="/movie/popular">
                <NavDropdown.Item>Popular</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/movie/now-playing">
                <NavDropdown.Item>Now Playing</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/movie/upcoming">
                <NavDropdown.Item>Upcoming</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/movie/top-rated">
                <NavDropdown.Item>Top Rated</NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>

            <NavDropdown
              title="TV Shows"
              className="mr-3"
              id="basic-nav-dropdown"
            >
              <LinkContainer to="/tv/popular">
                <NavDropdown.Item>Popular</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/tv/airing-today">
                <NavDropdown.Item>Airing Today</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/tv/on-the-air">
                <NavDropdown.Item>On TV</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/tv/top-rated">
                <NavDropdown.Item>Top Rated</NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>

            <NavDropdown
              title="People"
              className="mr-3"
              id="basic-nav-dropdown"
            >
              <LinkContainer to="/person">
                <NavDropdown.Item>Popular People</NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default Navigation
