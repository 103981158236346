import React from 'react'

const Heading = (props, ref) => {
  return (
    <div className="d-flex">
      <h4 ref={ref} className="d-inline mr-3 text-white">
        {props.title}
      </h4>
      {props.children}
    </div>
  )
}

export default React.forwardRef(Heading)
