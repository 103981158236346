import React, { useState, useRef } from 'react'
import { useQueryClient } from 'react-query'
import { Container } from 'react-bootstrap'
import { CSSTransition } from 'react-transition-group'
import Heading from '../components/Heading'
import Selector from '../components/Selector'
import ShowSlider from '../components/ShowSlider'
import Scroller from '../components/Scroller'
import SkeletonSlider from '../components/SkeletonSlider'
import Carousel from '../components/Carousel'

import {
  useMediaQuery,
  useOnScreen,
  useDailyTrending,
  useDisneyOriginals,
  useHuluOriginals,
  useMoviesNowPlaying,
  useNetflixOriginals,
  usePopularMovies,
  usePrimeOriginals,
  useTopRatedMovies,
  useTopRatedTv,
  useTvAiringToday,
  useTvOnTheAir,
  useWeeklyTrending,
  useAppleOriginals,
  useHboMaxOriginals,
  useParamountOriginals
} from '../hooks/index'

import '../App.css'

const HomeScreen = () => {
  const [trendingResource, setTrendingResource] = useState('dailyTrending')
  const [streamingResource, setStreamingResource] = useState('disneyOriginals')
  const [popularResource, setPopularResource] = useState('tvOnTheAir')
  const [moviesResource, setMoviesResource] = useState('popularMovies')
  const [tvResource, setTvResource] = useState('tvAiringToday')

  const queryClient = useQueryClient()

  const trendingRef = useRef()
  const streamingRef = useRef()
  const popularRef = useRef()
  const moviesRef = useRef()
  const tvRef = useRef()

  const trendingVisible = useOnScreen(trendingRef, '0px')
  const streamingVisible = useOnScreen(streamingRef, '0px')
  const popularVisible = useOnScreen(popularRef, '0px')
  const moviesVisible = useOnScreen(moviesRef, '0px')
  const tvVisible = useOnScreen(tvRef, '0px')

  const {
    isLoading: isLoadingDailyTrending,
    isError: isErrorDailyTrending,
    data: dailyTrending,
    error: dailyTrendingError
  } = useDailyTrending({
    enabled: trendingResource === 'dailyTrending' && trendingVisible
  })

  const {
    isLoading: isLoadingWeeklyTrending,
    isError: isErrorWeeklyTrending,
    data: weeklyTrending,
    error: weeklyTrendingError
  } = useWeeklyTrending({ enabled: trendingResource === 'weeklyTrending' })

  const {
    isLoading: isLoadingDisneyOriginals,
    isError: isErrorDisneyOriginals,
    data: disneyOriginals,
    error: disneyOriginalsError
  } = useDisneyOriginals({
    enabled: streamingResource === 'disneyOriginals' && streamingVisible
  })

  const {
    isLoading: isLoadingNetflixOriginals,
    isError: isErrorNetflixOriginals,
    data: netflixOriginals,
    error: netflixOriginalsError
  } = useNetflixOriginals({
    enabled: streamingResource === 'netflixOriginals'
  })

  const {
    isLoading: isLoadingPrimeOriginals,
    isError: isErrorPrimeOriginals,
    data: primeOriginals,
    error: primeOriginalsError
  } = usePrimeOriginals({ enabled: streamingResource === 'primeOriginals' })

  const {
    isLoading: isLoadingHuluOriginals,
    isError: isErrorHuluOriginals,
    data: huluOriginals,
    error: huluOriginalsError
  } = useHuluOriginals({ enabled: streamingResource === 'huluOriginals' })

  const {
    isLoading: isLoadingHboMaxOriginals,
    isError: isErrorHboMaxOriginals,
    data: hboMaxOriginals,
    error: hboMaxOriginalsError
  } = useHboMaxOriginals({ enabled: streamingResource === 'hboMaxOriginals' })

  const {
    isLoading: isLoadingAppleOriginals,
    isError: isErrorAppleOriginals,
    data: appleOriginals,
    error: appleOriginalsError
  } = useAppleOriginals({ enabled: streamingResource === 'appleOriginals' })

  const {
    isLoading: isLoadingParamountOriginals,
    isError: isErrorParamountOriginals,
    data: paramountOriginals,
    error: paramountOriginalsError
  } = useParamountOriginals({
    enabled: streamingResource === 'paramountOriginals'
  })

  const {
    isLoading: isLoadingTvOnTheAir,
    isError: isErrorTvOnTheAir,
    data: tVOnTheAir,
    error: tVOnTheAirError
  } = useTvOnTheAir({
    enabled: popularResource === 'tvOnTheAir' && popularVisible
  })

  const {
    isLoading: isLoadingMoviesNowPlaying,
    isError: isErrorMoviesNowPlaying,
    data: moviesNowPlaying,
    error: moviesNowPlayingError
  } = useMoviesNowPlaying({
    enabled: popularResource === 'moviesNowPlaying'
  })

  const {
    isLoading: isLoadingPopularMovies,
    isError: isErrorPopularMovies,
    data: popularMovies,
    error: popularMoviesError
  } = usePopularMovies({
    enabled: moviesResource === 'popularMovies' && moviesVisible
  })

  const {
    isLoading: isLoadingTopRatedMovies,
    isError: isErrorTopRatedMovies,
    data: topRatedMovies,
    error: topRatedMoviesError
  } = useTopRatedMovies({ enabled: moviesResource === 'topRatedMovies' })

  const {
    isLoading: isLoadingTvAiringToday,
    isError: isErrorTvAiringToday,
    data: tvAiringToday,
    error: tvAiringTodayError
  } = useTvAiringToday({ enabled: tvResource === 'tvAiringToday' && tvVisible })

  const {
    isLoading: isLoadingTopRatedTv,
    isError: isErrorTopRatedTv,
    data: topRatedTv,
    error: topRatedTvError
  } = useTopRatedTv(tvResource === 'topRatedTv')

  const prefetchResource = async resource => {
    await queryClient.prefetchQuery(resource, `fetch${resource}`)
  }

  const handleResource = resource => {
    switch (resource) {
      case 'dailyTrending':
      case 'weeklyTrending':
        setTrendingResource(resource)
        break
      case 'disneyOriginals':
      case 'netflixOriginals':
      case 'primeOriginals':
      case 'huluOriginals':
      case 'appleOriginals':
      case 'hboMaxOriginals':
      case 'paramountOriginals':
        setStreamingResource(resource)
        break
      case 'tvOnTheAir':
      case 'moviesNowPlaying':
        setPopularResource(resource)
        break
      case 'popularMovies':
      case 'topRatedMovies':
        setMoviesResource(resource)
        break
      case 'tvAiringToday':
      case 'topRatedTv':
        setTvResource(resource)
        break
      default:
    }
  }

  const handlePrefetch = resource => {
    prefetchResource(resource)
  }

  let isPageWide = useMediaQuery('(min-width: 1000px')

  return (
    <div>
      <Carousel shows={dailyTrending} />
      <Container>
        {/* TRENDING */}
        <Heading ref={trendingRef} title="Trending">
          <Selector
            types={[
              { type: 'Today', resourceType: 'dailyTrending' },
              { type: 'This Week', resourceType: 'weeklyTrending' }
            ]}
            selectorHandler={handleResource}
            prefetchHandler={handlePrefetch}
          />
        </Heading>

        {/* DAILY TRENDING */}
        {isLoadingDailyTrending && trendingResource === 'dailyTrending' ? (
          <SkeletonSlider />
        ) : null}

        {isErrorDailyTrending && trendingResource === 'dailyTrending' ? (
          <h1>{dailyTrendingError.message}</h1>
        ) : null}

        {trendingResource === 'dailyTrending' && isPageWide ? (
          <CSSTransition
            in={true}
            appear={true}
            timeout={600}
            classNames="fade"
          >
            <ShowSlider shows={dailyTrending} />
          </CSSTransition>
        ) : null}

        {trendingResource === 'dailyTrending' && !isPageWide ? (
          <CSSTransition
            in={true}
            appear={true}
            timeout={600}
            classNames="fade"
          >
            <Scroller shows={dailyTrending} />
          </CSSTransition>
        ) : null}

        {/* WEEKLY TRENDING */}
        {isLoadingWeeklyTrending && trendingResource === 'weeklyTrending' ? (
          <SkeletonSlider />
        ) : null}

        {isErrorWeeklyTrending && trendingResource === 'weeklyTrending' ? (
          <h1>{weeklyTrendingError.message}</h1>
        ) : null}

        {trendingResource === 'weeklyTrending' && isPageWide ? (
          <CSSTransition
            in={true}
            appear={true}
            timeout={600}
            classNames="fade"
          >
            <ShowSlider shows={weeklyTrending} />
          </CSSTransition>
        ) : null}

        {trendingResource === 'weeklyTrending' && !isPageWide ? (
          <CSSTransition
            in={true}
            appear={true}
            timeout={600}
            classNames="fade"
          >
            <Scroller shows={weeklyTrending} />
          </CSSTransition>
        ) : null}

        {/* STREAMING */}
        <Heading ref={streamingRef} title="Streaming">
          <Selector
            types={[
              { type: 'Disney +', resourceType: 'disneyOriginals' },
              { type: 'Netflix', resourceType: 'netflixOriginals' },
              { type: 'Prime', resourceType: 'primeOriginals' },
              { type: 'Hulu', resourceType: 'huluOriginals' },
              { type: 'Apple TV+', resourceType: 'appleOriginals' },
              { type: 'HBO Max', resourceType: 'hboMaxOriginals' },
              { type: 'Paramount+', resourceType: 'paramountOriginals' }
            ]}
            selectorHandler={handleResource}
            prefetchHandler={handlePrefetch}
          />
        </Heading>

        {/* DISNEY */}
        {isLoadingDisneyOriginals && streamingResource === 'disneyOriginals' ? (
          <SkeletonSlider />
        ) : null}

        {isErrorDisneyOriginals && streamingResource === 'disneyOriginals' ? (
          <h1>{disneyOriginalsError.message}</h1>
        ) : null}

        {streamingResource === 'disneyOriginals' && isPageWide ? (
          <CSSTransition
            in={true}
            appear={true}
            timeout={600}
            classNames="fade"
          >
            <ShowSlider shows={disneyOriginals} />
          </CSSTransition>
        ) : null}

        {streamingResource === 'disneyOriginals' && !isPageWide ? (
          <CSSTransition
            in={true}
            appear={true}
            timeout={600}
            classNames="fade"
          >
            <Scroller shows={disneyOriginals} />
          </CSSTransition>
        ) : null}

        {/* NETFLIX */}
        {isLoadingNetflixOriginals &&
        streamingResource === 'netflixOriginals' ? (
          <SkeletonSlider />
        ) : null}

        {isErrorNetflixOriginals && streamingResource === 'netflixOriginals' ? (
          <h1>{netflixOriginalsError.message}</h1>
        ) : null}

        {streamingResource === 'netflixOriginals' && isPageWide ? (
          <CSSTransition
            in={true}
            appear={true}
            timeout={600}
            classNames="fade"
          >
            <ShowSlider shows={netflixOriginals} />
          </CSSTransition>
        ) : null}

        {streamingResource === 'netflixOriginals' && !isPageWide ? (
          <CSSTransition
            in={true}
            appear={true}
            timeout={600}
            classNames="fade"
          >
            <Scroller shows={netflixOriginals} />
          </CSSTransition>
        ) : null}

        {/* PRIME */}
        {isLoadingPrimeOriginals && streamingResource === 'primeOriginals' ? (
          <SkeletonSlider />
        ) : null}

        {isErrorPrimeOriginals && streamingResource === 'primeOriginals' ? (
          <h1>{primeOriginalsError.message}</h1>
        ) : null}

        {streamingResource === 'primeOriginals' && isPageWide ? (
          <CSSTransition
            in={true}
            appear={true}
            timeout={600}
            classNames="fade"
          >
            <ShowSlider shows={primeOriginals} />
          </CSSTransition>
        ) : null}

        {streamingResource === 'primeOriginals' && !isPageWide ? (
          <CSSTransition
            in={true}
            appear={true}
            timeout={600}
            classNames="fade"
          >
            <Scroller shows={primeOriginals} />
          </CSSTransition>
        ) : null}

        {/* HULU */}
        {isLoadingHuluOriginals && streamingResource === 'huluOriginals' ? (
          <SkeletonSlider />
        ) : null}

        {isErrorHuluOriginals && streamingResource === 'huluOriginals' ? (
          <h1>{huluOriginalsError.message}</h1>
        ) : null}

        {streamingResource === 'huluOriginals' && isPageWide ? (
          <CSSTransition
            in={true}
            appear={true}
            timeout={600}
            classNames="fade"
          >
            <ShowSlider shows={huluOriginals} />
          </CSSTransition>
        ) : null}

        {streamingResource === 'huluOriginals' && !isPageWide ? (
          <CSSTransition
            in={true}
            appear={true}
            timeout={600}
            classNames="fade"
          >
            <Scroller shows={huluOriginals} />
          </CSSTransition>
        ) : null}

        {/* APPLE */}
        {isLoadingAppleOriginals && streamingResource === 'appleOriginals' ? (
          <SkeletonSlider />
        ) : null}

        {isErrorAppleOriginals && streamingResource === 'appleOriginals' ? (
          <h1>{appleOriginalsError.message}</h1>
        ) : null}

        {streamingResource === 'appleOriginals' && isPageWide ? (
          <CSSTransition
            in={true}
            appear={true}
            timeout={600}
            classNames="fade"
          >
            <ShowSlider shows={appleOriginals} />
          </CSSTransition>
        ) : null}

        {streamingResource === 'appleOriginals' && !isPageWide ? (
          <CSSTransition
            in={true}
            appear={true}
            timeout={600}
            classNames="fade"
          >
            <Scroller shows={appleOriginals} />
          </CSSTransition>
        ) : null}

        {/* HBO MAX */}
        {isLoadingHboMaxOriginals && streamingResource === 'hboMaxOriginals' ? (
          <SkeletonSlider />
        ) : null}

        {isErrorHboMaxOriginals && streamingResource === 'hboMaxOriginals' ? (
          <h1>{hboMaxOriginalsError.message}</h1>
        ) : null}

        {streamingResource === 'hboMaxOriginals' && isPageWide ? (
          <CSSTransition
            in={true}
            appear={true}
            timeout={600}
            classNames="fade"
          >
            <ShowSlider shows={hboMaxOriginals} />
          </CSSTransition>
        ) : null}

        {streamingResource === 'hboMaxOriginals' && !isPageWide ? (
          <CSSTransition
            in={true}
            appear={true}
            timeout={600}
            classNames="fade"
          >
            <Scroller shows={hboMaxOriginals} />
          </CSSTransition>
        ) : null}

        {/* PARAMOUNT PLUS */}
        {isLoadingParamountOriginals &&
        streamingResource === 'paramountOriginals' ? (
          <SkeletonSlider />
        ) : null}

        {isErrorParamountOriginals &&
        streamingResource === 'paramountOriginals' ? (
          <h1>{paramountOriginalsError.message}</h1>
        ) : null}

        {streamingResource === 'paramountOriginals' && isPageWide ? (
          <CSSTransition
            in={true}
            appear={true}
            timeout={600}
            classNames="fade"
          >
            <ShowSlider shows={paramountOriginals} />
          </CSSTransition>
        ) : null}

        {streamingResource === 'paramountOriginals' && !isPageWide ? (
          <CSSTransition
            in={true}
            appear={true}
            timeout={600}
            classNames="fade"
          >
            <Scroller shows={paramountOriginals} />
          </CSSTransition>
        ) : null}

        {/* WHATS POPULAR */}
        <Heading ref={popularRef} title="What's Popular">
          <Selector
            types={[
              { type: 'On TV', resourceType: 'tvOnTheAir' },
              { type: 'In Theaters', resourceType: 'moviesNowPlaying' }
            ]}
            selectorHandler={handleResource}
            prefetchHandler={handlePrefetch}
          />
        </Heading>

        {/* ON TV */}
        {isLoadingTvOnTheAir && popularResource === 'tvOnTheAir' ? (
          <SkeletonSlider />
        ) : null}

        {isErrorTvOnTheAir && popularResource === 'tvOnTheAir' ? (
          <h1>{tVOnTheAirError.message}</h1>
        ) : null}

        {popularResource === 'tvOnTheAir' && isPageWide ? (
          <CSSTransition
            in={true}
            appear={true}
            timeout={600}
            classNames="fade"
          >
            <ShowSlider shows={tVOnTheAir} />
          </CSSTransition>
        ) : null}

        {popularResource === 'tvOnTheAir' && !isPageWide ? (
          <CSSTransition
            in={true}
            appear={true}
            timeout={600}
            classNames="fade"
          >
            <Scroller shows={tVOnTheAir} />
          </CSSTransition>
        ) : null}

        {/* IN THEATERS */}
        {isLoadingMoviesNowPlaying && popularResource === 'moviesNowPlaying' ? (
          <SkeletonSlider />
        ) : null}

        {isErrorMoviesNowPlaying && popularResource === 'moviesNowPlaying' ? (
          <h1>{moviesNowPlayingError.message}</h1>
        ) : null}

        {popularResource === 'moviesNowPlaying' && isPageWide ? (
          <CSSTransition
            in={true}
            appear={true}
            timeout={600}
            classNames="fade"
          >
            <ShowSlider shows={moviesNowPlaying} />
          </CSSTransition>
        ) : null}

        {popularResource === 'moviesNowPlaying' && !isPageWide ? (
          <CSSTransition
            in={true}
            appear={true}
            timeout={600}
            classNames="fade"
          >
            <Scroller shows={moviesNowPlaying} />
          </CSSTransition>
        ) : null}

        {/* MOVIES */}
        <Heading ref={moviesRef} title="Movies">
          <Selector
            types={[
              { type: 'Popular', resourceType: 'popularMovies' },
              { type: 'Top Rated', resourceType: 'topRatedMovies' }
            ]}
            selectorHandler={handleResource}
            prefetchHandler={handlePrefetch}
          />
        </Heading>

        {/* POPULAR MOVIES */}
        {isLoadingPopularMovies && moviesResource === 'popularMovies' ? (
          <SkeletonSlider />
        ) : null}

        {isErrorPopularMovies && moviesResource === 'popularMovies' ? (
          <h1>{popularMoviesError.message}</h1>
        ) : null}

        {moviesResource === 'popularMovies' && isPageWide ? (
          <CSSTransition
            in={true}
            appear={true}
            timeout={600}
            classNames="fade"
          >
            <ShowSlider shows={popularMovies} />
          </CSSTransition>
        ) : null}

        {moviesResource === 'popularMovies' && !isPageWide ? (
          <CSSTransition
            in={true}
            appear={true}
            timeout={600}
            classNames="fade"
          >
            <Scroller shows={popularMovies} />
          </CSSTransition>
        ) : null}

        {/* TOP RATED MOVIES */}
        {isLoadingTopRatedMovies && moviesResource === 'topRatedMovies' ? (
          <SkeletonSlider />
        ) : null}

        {isErrorTopRatedMovies && moviesResource === 'topRatedMovies' ? (
          <h1>{topRatedMoviesError.message}</h1>
        ) : null}

        {moviesResource === 'topRatedMovies' && isPageWide ? (
          <CSSTransition
            in={true}
            appear={true}
            timeout={600}
            classNames="fade"
          >
            <ShowSlider shows={topRatedMovies} />
          </CSSTransition>
        ) : null}

        {moviesResource === 'topRatedMovies' && !isPageWide ? (
          <CSSTransition
            in={true}
            appear={true}
            timeout={600}
            classNames="fade"
          >
            <Scroller shows={topRatedMovies} />
          </CSSTransition>
        ) : null}

        {/* TV */}
        <Heading ref={tvRef} title="TV">
          <Selector
            types={[
              { type: 'Airing Today', resourceType: 'tvAiringToday' },
              { type: 'Top Rated', resourceType: 'topRatedTv' }
            ]}
            selectorHandler={handleResource}
            prefetchHandler={handlePrefetch}
          />
        </Heading>

        {/* TV AIRING TODAY */}
        {isLoadingTvAiringToday && tvResource === 'tvAiringToday' ? (
          <SkeletonSlider />
        ) : null}

        {isErrorTvAiringToday && tvResource === 'tvAiringToday' ? (
          <h1>{tvAiringTodayError.message}</h1>
        ) : null}

        {tvResource === 'tvAiringToday' && isPageWide ? (
          <CSSTransition
            in={true}
            appear={true}
            timeout={600}
            classNames="fade"
          >
            <ShowSlider shows={tvAiringToday} />
          </CSSTransition>
        ) : null}

        {tvResource === 'tvAiringToday' && !isPageWide ? (
          <CSSTransition
            in={true}
            appear={true}
            timeout={600}
            classNames="fade"
          >
            <Scroller shows={tvAiringToday} />
          </CSSTransition>
        ) : null}

        {/* TOP RATED TV */}
        {isLoadingTopRatedTv && tvResource === 'topRatedTv' ? (
          <SkeletonSlider />
        ) : null}

        {isErrorTopRatedTv && tvResource === 'topRatedTv' ? (
          <h1>{topRatedTvError.message}</h1>
        ) : null}

        {tvResource === 'topRatedTv' && isPageWide ? (
          <CSSTransition
            in={true}
            appear={true}
            timeout={600}
            classNames="fade"
          >
            <ShowSlider shows={topRatedTv} />
          </CSSTransition>
        ) : null}

        {tvResource === 'topRatedTv' && !isPageWide ? (
          <CSSTransition
            in={true}
            appear={true}
            timeout={600}
            classNames="fade"
          >
            <Scroller shows={topRatedTv} />
          </CSSTransition>
        ) : null}
      </Container>
    </div>
  )
}

export default HomeScreen
