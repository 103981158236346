import React, { Component } from 'react'
import Slider from 'react-slick'
import SkeletonCard from './SkeletonCard'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './SkeletonSlider.css'

class SkeletonSlider extends Component {
  render() {
    const settings = {
      lazyLoad: true,
      infinite: false,
      speed: 500,
      slidesToShow: 8,
      slidesToScroll: 8
    }

    return (
      <Slider {...settings}>
        {Array(20)
          .fill()
          .map((item, index) => (
            <SkeletonCard key={index} />
          ))}
      </Slider>
    )
  }
}

export default SkeletonSlider
