import React, { useState, useEffect, useRef } from 'react';

import './Selector.css';

const Selector = ({ types, selectorHandler, prefetchHandler }) => {
  const [active, setActive] = useState(types[0].type);

  const parentRef = useRef(null);
  const targetRef = useRef(null);
  const highlightRef = useRef(null);

  const handleClick = (type, resourceType) => {
    setActive(type);
    selectorHandler(resourceType, type);
  };

  const handleOnMouseEnter = resourceType => {
    prefetchHandler(resourceType);
  };

  useEffect(() => {
    const parent = parentRef.current.getBoundingClientRect();
    const target = targetRef.current.getBoundingClientRect();

    highlightRef.current.style.left = target.left - 1.1 - parent.left + 'px';
    highlightRef.current.style.width = target.width + 'px';
  }, [active]);

  return (
    <div>
      <div
        className="selector"
        self="size-x1"
        layout="row center-left"
        ref={parentRef}
      >
        <div ref={highlightRef} className="highlight"></div>
        {types.map(type => (
          <div
            key={type.type}
            onMouseEnter={() => handleOnMouseEnter(type.resourceType)}
            onClick={() => handleClick(type.type, type.resourceType)}
            ref={active === type.type ? targetRef : null}
            className={
              active === type.type
                ? `selector-item selector-item--active`
                : 'selector-item'
            }
          >
            {type.type}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Selector;
