import { useEffect, useState } from 'react'

export const useOnScreen = (ref, rootMargin = '0px') => {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState(false)
  useEffect(() => {
    const refCopy = ref.current

    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting)
      },
      {
        rootMargin
      }
    )
    if (refCopy) {
      observer.observe(refCopy)
    }
    return () => {
      observer.unobserve(refCopy)
    }
  }, [ref, rootMargin]) // Empty array ensures that effect is only run on mount and unmount
  return isIntersecting
}
